.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgb(254, 237, 216);
  min-height: 100vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
}

a {
  color: black;
}

.App-header {
  padding: 30px;
  color: black;
}

.App-header h1 {
  margin: 0;
  font-size: 2.5em;
  font-weight: nor;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
}

.nav-links li {
  margin: 0 15px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border: 2px solid #a0a0a0;
  box-shadow: 1px 1px 0 #ffffff inset, -1px -1px 0 #707070 inset;
  transition: all 0.2s ease;
}

.nav-links li:hover {
  background-color: #c0c0c0;
  box-shadow: 1px 1px 0 #707070 inset, -1px -1px 0 #ffffff inset;
}

.nav-links li.selected {
  background-color: #c0c0c0;
  box-shadow: 1px 1px 0 #707070 inset, -1px -1px 0 #ffffff inset;
}


.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.main-image {
  max-width: 20%;
  height: auto;
  margin-right: 20px;
}

.main-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
}

.main-text-box {
  max-width: 30%;
  padding: 10px;
  box-sizing: border-box;
  text-align: justify;
}

.full-width-text-box {
  max-width: 42%;
  margin: 20px auto;
  padding: 10px;
  box-sizing: border-box;
  text-align: justify;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.main-further-text {
  text-align: left;
  max-width: 42%;
  font-size: 16px;
  align-items: center;
  margin: 0 auto;
}

.App-footer {
  background-color: #282c34;
  padding: 10px;
  color: white;
  text-align: center;
}
