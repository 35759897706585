.projects-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  
  .projects-title-bar {
    width: 52%;
    background-color: #000080;
    color: #fff;
    padding: 5px;
    text-align: left;
    box-sizing: border-box;
  }
  
  .projects-title {
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    max-height: 60vh; /* Limit height to make it scrollable */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 2px solid #a0a0a0;
    padding: 10px;
    box-shadow: 1px 1px 0 #ffffff inset, -1px -1px 0 #707070 inset;
    background-color: #e0e0e0;
  }
  
  .project-window {
    display: flex;
    align-items: center;
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid #a0a0a0;
    box-shadow: 1px 1px 0 #ffffff inset, -1px -1px 0 #707070 inset;
    background-color: #c0c0c0;
  }
  
  .project-image {
    max-width: 100px;
    max-height: 100px;
    margin-right: 30px;
  }
  
  .project-text {
    text-align: left;
    color: black;
  }
  
  .projects-container::-webkit-scrollbar {
    width: 12px;
    background-color: #e0e0e0;
  }
  
  .projects-container::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border: 2px solid #707070;
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  
  .projects-container::-webkit-scrollbar-track {
    background-color: #c0c0c0;
    border: 2px solid #a0a0a0;
  }
  